import CbaLogo from "assets/img/brand/cba/justicia-logo.png";
import CbaLogoLight from "assets/img/brand/cba/justicia-logo-light.png";
import CbaFav from "assets/img/brand/cba/favicon-cordoba.png";

import SLLogo from "assets/img/brand/sl/justicia-logo-san-luis.png";
import SLLogoLight from "assets/img/brand/sl/logo-san-luis-light.png";
import SLFav from "assets/img/brand/sl/favicon-san-luis.png";

import SELogo from "assets/img/brand/subastas_electronicas/se-logo.png";
import SELogoLight from "assets/img/brand/subastas_electronicas/se-logo-light.png";
import SEFav from "assets/img/brand/subastas_electronicas/se-iso-bordered-inverted.png";
import DefaultSE from "assets/img/brand/subastas_electronicas/default.png";

import ERLogo from "assets/img/brand/er/logo_er.png"
import ERLogoLight from "assets/img/brand/er/logo_er_inverted.png";
import ERFav from "assets/img/brand/er/er_iso.png";

import LPLogo from "assets/img/brand/lp/justicia-logo-lp.png"
import LPLogoLight from "assets/img/brand/lp/justicia-logo-lp-light.png";
import LPFav from "assets/img/brand/lp/lp_iso.png";

import JujuyLogo from "assets/img/brand/jujuy/justicia-logo-jujuy.png";
import JujuyLogoLight from "assets/img/brand/jujuy/justicia-logo-jujuy-light.png";
import JujuyFav from "assets/img/brand/jujuy/jujuy_iso.png";

import SaltaLogo from "assets/img/brand/salta/justicia-logo-salta.png";
import SaltaLogoLight from "assets/img/brand/salta/justicia-logo-salta-light.png";
import SaltaFav from "assets/img/brand/salta/salta_iso.png";

import ChacoLogo from "assets/img/brand/chaco/justicia-logo-chaco.png";
import ChacoLogoLight from "assets/img/brand/chaco/justicia-logo-chaco-light.png";
import ChacoFav from "assets/img/brand/chaco/chaco_iso.png";

import JuGobLogo from "assets/img/brand/jugob/gobierno-logo-jujuy.webp";
import JuGobLogoLight from "assets/img/brand/jugob/gobierno-logo-jujuy-light.webp";
import JuGobFav from "assets/img/brand/jugob/jugob_iso.webp";
import DefaultJuGob from "assets/img/brand/jugob/defaultJuGob.webp";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'privadas';

export const sources = {
    cba: {
        url: 'https://subastas.justiciacordoba.gob.ar',
        logo: CbaLogo,
        logolight: CbaLogoLight,
        ws_path: 'wss://subastas.justiciacordoba.gob.ar/wss',
        name: 'Córdoba',
        navbar_class: 'navbar-cba',
        headroom_class: 'headroom-cba',
        favicon: CbaFav,
        theme_color: '#00857D',
        theme_name: 'cordoba',
    },
    sl: {
        url: 'https://subastas.justiciasanluis.gov.ar',
        logo: SLLogo,
        logolight: SLLogoLight,
        ws_path: 'wss://subastas.justiciasanluis.gov.ar/wss',
        name: 'San Luis',
        navbar_class: 'navbar-sl',
        headroom_class: 'headroom-sl',
        favicon: SLFav,
        theme_color: '#A80019',
        theme_name: 'sanluis',
    },
    er: {
        url:'https://subastas.jusentrerios.gob.ar',
        logo: ERLogo,
        logolight: ERLogoLight,
        ws_path: 'wss://subastas.jusentrerios.gob.ar/wss',
        name: 'Entre Ríos',
        navbar_class: 'navbar-er',
        headroom_class: 'headroom-er',
        favicon: ERFav,
        theme_color: '#676978',
        theme_name: 'entrerios',
    },
    lp:{
        url:'https://subastas.justicialapampa.gob.ar',
        logo: LPLogo,
        logolight: LPLogoLight,
        ws_path: 'wss://subastas.justicialapampa.gob.ar/wss',
        name: 'La Pampa',
        navbar_class: 'navbar-lp',
        headroom_class: 'headroom-lp',
        favicon: LPFav,
        theme_color: '#006699',
        theme_name: 'lapampa',
    },
    jujuy: {
        url: 'https://subastas.mpajujuy.gob.ar',
        logo: JujuyLogo,
        logolight: JujuyLogoLight,
        ws_path: `wss://subastas.mpajujuy.gob.ar/wss`,
        name: 'MPA Jujuy',
        navbar_class: 'navbar-jujuy',
        headroom_class: 'headroom-jujuy',
        favicon: JujuyFav,
        theme_color: '#516391',
        theme_name: 'jujuy',
    },
    salta: {
        url: 'https://subastas.justiciasalta.gov.ar',
        logo: SaltaLogo,
        logolight: SaltaLogoLight,
        ws_path: `wss://subastas.justiciasalta.gov.ar/wss`,
        name: 'Salta',
        navbar_class: 'navbar-salta',
        headroom_class: 'headroom-salta',
        favicon: SaltaFav,
        theme_color: '#ae553b',
        theme_name: 'salta',
    },
    chaco: {
        url: 'https://subastas.justiciachaco.gov.ar',
        logo: ChacoLogo,
        logolight: ChacoLogoLight,
        ws_path: `wss://subastas.justiciachaco.gob.ar/wss`,
        name: 'Chaco',
        navbar_class: 'navbar-chaco',
        headroom_class: 'headroom-chaco',
        favicon: ChacoFav,
        theme_color: '#007f01',
        theme_name: 'chaco',
    },
    jugob: {
        url: 'https://subastas.jujuy.gob.ar',
        logo: JuGobLogo,
        logolight: JuGobLogoLight,
        ws_path: `wss://subastas.jujuy.gob.ar/wss`,
        name: 'Jujuy Gob.',
        navbar_class: 'navbar-jugob',
        headroom_class: 'headroom-jugob',
        favicon: JuGobFav,
        theme_color: '#007f01',
        theme_color: '#13304D',
        theme_name: 'jugob',
    },
    privadas: {
        url: document.location.origin,
        logo: SELogo,
        logolight: SELogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Electrónicas',
        navbar_class: 'navbar-transparent',
        headroom_class: 'headroom',
        favicon: SEFav,
        theme_color: '#f58723',
        theme_name: 'subastas',
        defaultImg: DefaultSE,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Electrónicas',
        recaptcha_key: '6Lc4faUZAAAAAEYGjXjLNThImMufcUROENC3pAq-',
        gtm_ID: 'GTM-P8LTPVC',
        support_email: 'publicas@esubasta.ar'
    },
};